import React from "react";

const Education = () => {
  const educationContent = [
    {
      id: 1,
      colClass: "left",
      title:  "Education",
      education: [
        {
          id: 1,
          year: "2024 - 2025",
          degree: "Venture Capital & StartUps Analysis",
          skills:"Venture Capital · The Exit · Valorations · CapTable · Teams & Market · B2C Analysis · B2B Analysis · Legal Terms · Funds Strategy · TechTransfer & Deeptech ",
          institute: "Boske · Online",
        },
        {
          id: 2,
          year: "2023 - 2024",
          degree: "Master on Digital Marketing and E-commerce",
          skills:"360º · SEO · Facebook Ads · Instagram Ads · Google Ads · Social Media · Growth Hacking · Influencers Marketing · CMO · Agencies · Content Marketing · Copywriting · Shopping online · Ecommerce Legal Aspects · Logistics ",
          institute: "The Power Business School · Online",
        },
        {
          id: 3,
          year: "2021 - 2022",
          degree: "Harvard Business Publishing Certifications",
          skills:"Persuading Others · Leading People · Project Management · Negotiating · Time Management · Digital Intelligence",
          institute: "The Power Business School · Harvard ManageMentors · Online",
        },
        {
          id: 4,
          year: "2021 - 2022",
          degree: "The Power MBA",
          skills:"Digital Marketing · Team Leadership · Product Innovation · Business Strategy · Lean Startup · Financial Accounting · Entrepreneurship",
          institute: "The Power Business School · Online",
        },
        {
        id: 5,
        year: "2019 - 2019",
        degree: "PDD - Executive Program",
        skills:"Finance · Economy · Operations Management · Team Management · Negotiation · Communication · Marketing · Financial Accounting · Self-Knowledge · Business Ethics",
        institute: "IESE Business School · Madrid",
      },
      {
        id: 6,
        year: "2010 - 2011",
        degree: "Specialist in Industrial Design and Product Development",
        skills:"Computer-Aided Design (CAD) · Solid model and simulation · Design and development of industrial products.",
        institute: "UNED · Online",
      },
      {
        id: 7,
        year: "2000 - 2009",
        degree: "Telecommunication Engineer",
        skills:"IoT · freeRTOS · LPWAN · Network Engineering · Wireless Communication · Mobile Networks · RF (Radio Frequency) · Network Architecture · Data Networking · 5G Technology · Cybersecurity · Standards · Infrastructure · Regulations ",
        institute: "UPV/EHU Basque Country University · Bilbao",
      },
      ],
    },
  ];
  return (
    <>
      {educationContent.map((item) => (
        //<div className={item.colClass} key={item.id}>
           <div key={item.id}>
          <div className="tokyo_section_title">
            <h3>{item.title}</h3>
          </div>
          <div className="tokyo_tm_resume_list">
            <ul>
              {item?.education?.map((value) => (
                <li key={value.id}>
                  <div className="list_inner">
                    <div className="time">
                      <span>{value.year}</span>
                    </div>
                    <div className="place">
                      <h3 style={{color:"#333b4d"}}>{value.institute}</h3>
                      <span>{value.degree}</span>
                      <div><span><br></br><strong>Skills:</strong> {value.skills}</span></div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default Education;
