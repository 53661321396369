import React, { useState } from "react";
import { useFormFields, useMailChimpForm } from "./use-mailchimp-form.tsx";

export default function App() {
  const url =
    "https://asiergonzalez.us9.list-manage.com/subscribe/post?u=16aabb7f525284c0ceb6f0060&amp;id=a161b75c14";
  const { loading, error, success, message, handleSubmit } = useMailChimpForm(url);
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: "",
  });

  const [showPopup, setShowPopup] = useState(false);

  // Show popup if there is a message (error or success)
  React.useEffect(() => {
    if (loading || error || success) {
      setShowPopup(true);
    }
  }, [loading, error, success]);

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(fields);
        }}
        style={{ maxWidth: "400px", margin: "0 auto", position: "relative" }}
      >
        <input
          style={{
            outline: "none",
            border: "none",
            borderBottom: "2px solid #ccc", // Solo línea inferior
            padding: "10px 0",
            marginBottom: "20px",
            width: "100%",
            fontSize: "16px",
            boxSizing: "border-box",
            background: "transparent", // Fondo transparente
            color: "#00ca77", // Color del texto
          }}
          id="EMAIL"
          type="email"
          placeholder="Email" // Placeholder en lugar de label
          value={fields.EMAIL}
          onChange={handleFieldChange}
        />
        <button
          style={{
            backgroundColor: "#00ca77",
            border: "none",
            color: "white",
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          SUBSCRIBE
        </button>
      </form>

      {/* Popup modal for messages */}
      {showPopup && (
        <>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: "1000",
              textAlign: "center",
            }}
          >
            {loading && <p style={{ color: "#00ca77" }}>Submitting...</p>}
            {error && <p style={{ color: "#00ca77" }}>{message}</p>}
            {success && <p style={{ color: "#00ca77" }}>{message}</p>}
            <button
              onClick={() => setShowPopup(false)} // Close popup on click
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                backgroundColor: "#00ca77",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </div>

          {/* Optional background overlay */}
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: "999",
            }}
            onClick={() => setShowPopup(false)} // Close popup on background click
          ></div>
        </>
      )}
    </>
  );
}
