import React from "react";
import "./unsubscribe.css"; // Importamos el archivo CSS
import Social from "../Social";

const UnSubscribePage = () => {
  return (
    <div className="unsubscribe-container">
      <header className="header">
          <div className="profileImage">
            <img src="assets/img/slider/1.png" alt="Asier Gonzalez" />
          </div>
          <h2>Asier Gonzalez</h2>
          <Social />
        </header>
      <main className="unsubscribe-main">
        <h2>Unsubscribe Successful</h2>
        <p>You will no longer receive email marketing from this list.</p>
        <p>Thank you for letting me know. If this was a mistake, you can resubscribe at any time.</p>
        <div className="unsubscribe-return">
          <a href="/">&laquo; Return to my website</a>
        </div>
      </main>
    </div>
  );
};

export default UnSubscribePage;
