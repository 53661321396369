import React from "react";
import Unsubscribe from "../../components/mailchimp/UnSubscribe";
import "../../components/mailchimp/unsubscribe.css";

const UnSubscribePage = () => {

    return (
        <div className="unsubscribe-container">
      <Unsubscribe />
    </div>
  );
}

export default UnSubscribePage;
