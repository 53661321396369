import React from "react";
import { useFormFields, useMailChimpForm } from "./use-mailchimp-form.tsx";

export default function App() {
  const url =
    "https://asiergonzalez.us9.list-manage.com/subscribe/post?u=16aabb7f525284c0ceb6f0060&amp;id=a161b75c14";
  const { loading, error, success, message, handleSubmit } = useMailChimpForm(
    url
  );
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: ""
  });

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(fields);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ position: "relative", width: "100%", maxWidth: "400px" }}>
          <input
            id="EMAIL"
            type="email"
            value={fields.EMAIL}
            onChange={handleFieldChange}
            style={{
              outline: "none",
              border: "none",
              borderBottom: "2px solid #333b4d",
              padding: "8px 0",
              fontSize: "16px",
              width: "100%",
            }}
            placeholder="Email"
          />
        </div>
        <button
          style={{
            backgroundColor: "#00ca77",
            border: "none",
            color: "white",
            padding: "15px 32px",
            marginLeft: "10px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          SUBSCRIBE
        </button>
      </form>
      <div
        style={{
          color: "#00ca77",
          marginTop: "20px",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        {loading && "Submitting..."}
        {error && message}
        {success && message}
      </div>
    </>
  );
}

