import React from "react";

const Experience = () => {
  const resumeContent = [
    {
      id: 1,
      colClass: "right",
      title: "Experience",
      resume: [
        {
          id: 1,
          year: "2019 - 2024",
          degree1: "Global Product and Solution Manager",
          degree2:"",
          specialities:"IoT | Business | Product Management | People Management | Stakeholder Management | Relationship Building | Understanding the customer | Product and Service Development | Requirement Analysis | Pricing | Planning | Competitive Analysis | Sales Planning | Inventory Control | Finacial Planning | Strategy",
          skills:"Problem Solving · Product Lifecycle Management · Product Launch · Strategic Roadmaps · User Experience (UX) · Innovation Management · Design Thinking · Competitive Analysis · Strategic Partnerships and Alliances · Technical Proficiency",
          institute: "SGS · Global . Madrid · Digital & Innovation",
        },
        {
          id: 2,
          year: "2017 - Now",
          degree1: "· 2024 - Now · Elevenais · Marketing and Innovation Advisor",
          degree2: "· 2024 - Now · Contributor · The Valley Business & Tech School",
          degree3: "· 2024 - Now · Mentor · IESE WeStart Mentoring Program",
          degree4: "· 2024 - Now · Private Investor · StartUpXplore",
          degree5: "· 2017 - Now · Conference Speaker",
          degree6: "· 2018 - 2024 · Project Evaluator for European ICT Projects · EUREKA · EU Commission",
          degree7: "· 2017 - 2020 · Smart Cities Lecturer for Smart Cities Master · Structuralia",
          specialities:"IoT | Digital Transformation | Innovation | Project Evaluator | Consulting | Advisory Services | Transformational Growth | Smart Cities | Smart Solutions | Protocols and Standards | Platforms Expert | Private Investor | Connectivity | Hardware | Edge Computing | Web Applications | AI | Business Intelligence | Azure | IoTHub | Marketing | Web Page Development | Marketing Material | Roadmaps | Business Model Canvas | Lecturer | Speaker",
          skills:"Strategy · E-Commerce · Innovation Consulting · Technical Proficiency",
          institute: "ASIER · Strategic Advisor · Investor · Educator · Mentor",
        },
        {
          id: 3,
          year: "2015 - 2019",
          degree1: "IoT And Smart Cities Manager",
          degree2:"",
          specialities:"IoT & Smart Cities Expert | Water & Energy Industries | Open Innovation | Digital Transformation | Cutting-Edge Technologies | Public Tenders | IoT Initiatives | Smart Cities | Technical Architectures | Energy Consumption | Water Quality | Critical Infrastructures | Spin Off | Development Teams | Digital Partnerships | OneSait | arcGIS | IDBOX | LoraWAN | Sigfox | Angular | Libelium ",
          skills:"Business Development · Digital Transformation · Innovation Consulting · Product Development · Product Innovation",
          institute: "TPF ENGINEERING · Madrid · Engineering Consultancy",
        },
        {
          id: 4,
          year: "2013 - 2015",
          degree1: "Founder · Product Manager",
          degree2:"",
          specialities:"Tech Innovation | Market Analysis | Investor Pitching | Product Development | Marketing Strategy | Strategic Partnership | Negotiation Skills | Startup Financing | Incubator Program | Cutting-Edge Technologies | Investement Attraction | Networking and Relationship Building | Trade Shows and Events | POCs | MVPs | Strategic Thinking | Libelium | Java | Angular | Cloud | HW Design",
          skills:"IProduct Management · Competitive Analysis · Startups · Product Development · Entrepreneurship · Product Innovation · Technical Proficiency · Marketing",
          institute: "EOIT · Bilbao · Tech Start Up",
        },
        {
          id: 5,
          year: "2009 - 2013",
          degree1: "Hardware/Firmware Engineer",
          degree2:"Quality Manager",
          specialities:"Hardware Tools | Prototyping | Firmware | Validation Software | Smart Agriculture | Smart Devices | Smart Metering | Smart Cities | LoRaWAN | Coronis | CE/FCC Certification | Innovation | Quality Assurance | Performance Testing | Manufacturing Process | Compliance | Emerging Technologies | Mass Manufacturing | Process Optimization | KPIs | Data-driven Decision Making | ISO 9001 | Continuous Improvement | Top Management Insights | Process Enhacement | Proactive Risk Management | Operational Efficiency | Cross-Functional Leadership",
          skills:"Quality Management · Corrective and Preventive Action (CAPA) · Quality Auditing · Continuous Improvement · Cross-functional Team Leadership · Research and Development (R&D) · Manufacturing Engineering · Testing Methodologies and Validation · Hardware and Firmware Development",
          institute: "ARSONDATA METERING· Bilbao · Engineering",
        },
        {
          id: 6,
          year: "2008 - 2009",
          degree1: "Junior Engineer",
          degree2:"",
          specialities:"M2M Remote Stations Device Debugging and Development | Water and Energy Sectors | Embedded Systems Development | Cellular Remote Monitoring Expertise | Telecontrol | University Final Project with Honors",
          skills:"M2M Communication Expertise · Embedded Systems Development",
          institute: "ELECNOR GROUP · Bilbao · Renewable Engineering",
        },
        
        
      ],
    },
  ];
  return (
    <>
      {resumeContent.map((item) => (
        // <div className={item.colClass} key={item.id}>
        <div key={item.id}>
          <div className="tokyo_section_title">
            <h3>{item.title}</h3>
          </div>
          <div className="tokyo_tm_resume_list">
            <ul>
              {item?.resume?.map((value) => (
                <li key={value.id}>
                  <div className="list_inner">
                    <div className="time">
                      <span>{value.year}</span>
                    </div>
                    <div className="place">
                      <h3 style={{color:"#333b4d"}}>{value.institute}</h3>
                      <span>{value.degree1}</span>
                      <div><span>{value.degree2}</span></div>
                      <div><span>{value.degree3}</span></div>
                      <div><span>{value.degree4}</span></div>
                      <div><span>{value.degree5}</span></div>
                      <div><span>{value.degree6}</span></div>
                      <div><span>{value.degree7}</span></div><br></br>
                      <div><span><strong>Specialities:</strong> {value.specialities}</span></div><br></br>
                      <div><span><strong>Skills:</strong> {value.skills}</span></div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default Experience;
