import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "../../tabs/tabs.tsx";
import CopyRight from "../../components/CopyRight";
import PageTitle from "../../components/PageTitle";
import Social from "../../components/Social";
import { Outlet } from "react-router-dom";
import Legal from "../../components/Legal.jsx";
import Nav from "../../components/mobilemenu/Nav.jsx";
import CookieConsent from "react-cookie-consent";



const HomeLight = () => {



  function clickOnHome() {
    setTimeout(() => { window.scrollTo(0, 0); }, 200);
    window.dataLayer.push({ 'event': 'home_view' });
  }

  function clickOnAbout() {
    setTimeout(() => { window.scrollTo(0, 0); }, 200);
    window.dataLayer.push({ 'event': 'about_view' });
  }

  function clickOnPortfolio() {
    setTimeout(() => { window.scrollTo(0, 0); }, 200);
    window.dataLayer.push({ 'event': 'portfolio_view' });

  }
  function clickOnBlog() {
    setTimeout(() => { window.scrollTo(0, 0); }, 200);
    window.dataLayer.push({ 'event': 'blog_view' });

  }

  function clickOnServices() {
    setTimeout(() => { window.scrollTo(0, 0); }, 200);
    window.dataLayer.push({ 'event': 'services_view' });

  }

  return (
    <>
      <PageTitle title="Eyes On Inspiring Technology" />
      <Tabs>
        <TabList>
          {/* START LEFT MENU CONTENT */}
          <div className="leftpart">
            <div className="leftpart_inner">
              <div className="logo">
                <a className="navbar-brand" to="/">
                  <a href="/"> <img src="/assets/img/logo/dark.png" alt="brand" /></a>
                </a>
              </div>
              {/* END LOGO */}
              <div className="menu">
                <ul>
                  <Tab to="/">
                    <span className="menu_content" onClick={clickOnHome}>Home</span>
                  </Tab>
                  <Tab to="/about">
                    <span className="menu_content" onClick={clickOnAbout}>About</span>
                  </Tab>
                  {/* <Tab to="/services">
                    <span className="menu_content" onClick={clickOnServices}>What I do</span>
                  </Tab> */}
                  <Tab to="/portfolio">
                    <span className="menu_content" onClick={clickOnPortfolio}>Portfolio</span>
                  </Tab>
                  <Tab to="/blog">
                    <span className="menu_content" onClick={clickOnBlog}>Blog</span>
                  </Tab>
                </ul>
              </div>
              {/* END MENU */}
              <Social />
              <br />
              <CopyRight />
              <Legal />
              <Nav />
              {/* END COPYRIGHT */}
            </div>
          </div>
          {/* END LEFT MENU CONTENT */}
        </TabList>
        {/* END SIDEBAR TABLIST */}

        {/* START RIGHT PART CONTENT */}
        <div className="rightpart">
          <div className="rightpart_in">
            <div className="tokyo_tm_section">
              <TabPanel>
                <div data-aos="fade-right" data-aos-duration="1200">
                  <Outlet />
                </div>
              </TabPanel>
              {/* END HOME MENU TAB CONTENT */}
            </div>
          </div>
        </div>
        {/* END RIGHT PART CONTENT */}
      </Tabs>
      {/* END TABS */}
      <CookieConsent
        location="bottom"
        buttonText="Accept!!"
        style={{ background: "#333b4d" }}
        buttonStyle={{ backgroundColor:"#00ca77", color: "#FFFFFF", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </>
  );
};

export default HomeLight;
