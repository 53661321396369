import React from "react";
import Links from "../../components/linktree/Links";
import "../../components/linktree/linktree.css";

const LinkTree = () => {

    return (
        <div className="linktree-container">
      <Links />
    </div>
  );
}

export default LinkTree;
